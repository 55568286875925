/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import moment from 'moment';
import inputLengthConstants from '../constants/inputLengthConstants';
import { ensure } from './arrayUtils';
import NumberUtils from './numberUtils';
import StringUtils from './stringUtils';

export interface ValidationConfig {
    required?: boolean;
    inputLength?: number;
}

class ValidationUtils {
    static validateInputStringLength(inputValue?: string, { required, inputLength }: ValidationConfig = { required: true }) {
        required = required === undefined ? true : required;

        if(required && StringUtils.isNullOrWhitespace(inputValue)) {
            return false;
        }
        if(inputLength && !StringUtils.isNullOrWhitespace(inputValue) && ensure(inputValue).length > inputLength) {
            return false;
        }

        return true;
    }

    static validateInputNumber(inputValue?: number, { required, inputLength }: ValidationConfig = { required: true }) {
        required = required === undefined ? true : required;

        if(required && NumberUtils.isNullOrWhitespace(inputValue)) {
            return false;
        }

        if(inputLength && !NumberUtils.isNullOrWhitespace(inputValue) && ensure(inputValue) > inputLength) {
            return false;
        }

        return true;
    }

    static validateDateTimeRangeStart(startDate?: Date, endDate?: Date) {
        return !!startDate && (!endDate || moment(endDate).isSameOrAfter(startDate));
    }

    static validateStrictDateTimeRangeStart(startDate?: Date, endDate?: Date) {
        return !!startDate && (!endDate || moment(endDate).isAfter(startDate));
    }

    static validateDateTimeRangeTo(startDate?: Date, endDate?: Date) {
        return !startDate || !endDate || moment(endDate).isSameOrAfter(startDate);
    }

    static validateDateFormat(dateTime?: any) {

        return dateTime ? moment(dateTime).isValid() === true && moment(dateTime).isSameOrAfter(moment(new Date(1753, 0, 1))) : true;
    }

    static validateStrictDateTimeRangeTo(startDate?: Date, endDate?: Date) {
        return !startDate || !endDate || moment(endDate).isAfter(startDate);
    }

    static validateOptionalStringWithMaxLength(inputValue?: string) {
        return this.validateInputStringLength(inputValue, {
            required: false,
            inputLength: inputLengthConstants.StringMax
        });
    }

    static validateRequiredStringWithMaxLength(inputValue?: string) {
        return this.validateInputStringLength(inputValue, {
            required: true,
            inputLength: inputLengthConstants.StringMax
        });
    }

    static validateOptionalNumberWithMaxLength(inputValue?: number) {
        return this.validateInputNumber(inputValue, {
            required: false,
            inputLength: inputLengthConstants.StringMax
        });
    }

    static validateRequiredNumberWithMaxLength(inputValue?: number) {
        return this.validateInputNumber(inputValue, {
            required: true,
            inputLength: inputLengthConstants.StringMax
        });
    }

    static validateEmail(email?: string, multipleEmails = false): boolean {
        const regexp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        let validationResult = true;
        if(multipleEmails && email !== undefined && email) {
            const emails = email.split(',');
            emails.forEach((element: string) => {
                const validation = element ? regexp.test(element) : false;
                validationResult = validationResult && validation;
            });
        }
        else {
            validationResult = email ? regexp.test(email) : false;
        }

        return validationResult;
    }

    static validateInputStringSpecialCharacters(input?: string): boolean {
        const regexp = new RegExp(/[!$%^&*()+|~=`{}\\[\]:\\/;<>?,.#]/);
        let validationResult = true;
        validationResult = input ? !regexp.test(input) : true;

        return validationResult;
    }

}

export default ValidationUtils;
